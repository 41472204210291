import React from "react";
import Navbar from "./Navbar";
import Banner from "./Banner";
import About from "./About";
import Problem from "./Problem";
import Solution from "./Solution";
import Founders from "./Founders";
import Contact from "./Contact";
import Footer from "./Footer";

export default function LandingPage() {
  return (
    <div>
      <Navbar />
      <div className=" max-w-6xl m-auto px-10 lg:px-3">
        <Banner />
        <About />
        <Problem />
        <Solution />
        <Founders />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}
