import React from "react";
import { cn } from "../../lib/utils";
import { IoIosSearch } from "react-icons/io";
import Button from "../../components/ui/Button";
import { FaAngleDown } from "react-icons/fa6";

export default function Filter() {
  return (
    <div className=" flex gap-3 mt-8">
      <div className=" relative flex-1">
        <p className=" absolute top-1/2 left-2 -translate-y-1/2 ">
          <IoIosSearch size={16} className=" text-custom" />
        </p>
        <input
          className={cn("ipt pl-8 text-sm w-full bg-white")}
          type={"text"}
          placeholder="Search"
        />
      </div>
      <Button
        className={
          "btn-secondary text-sm py-1 flex gap-2 items-center bg-white border-[#ABABAC]"
        }
      >
        This Week <FaAngleDown />
      </Button>
      <Button
        className={
          "btn-secondary text-sm py-1 flex gap-2 items-center bg-white border-[#ABABAC]"
        }
      >
        Location <FaAngleDown />
      </Button>
      <Button
        className={
          "btn-secondary text-sm py-1 flex gap-2 items-center bg-white border-[#ABABAC]"
        }
      >
        Number of weeks <FaAngleDown />
      </Button>
      <Button
        className={
          "btn-secondary text-sm py-1 flex gap-2 items-center bg-white border-[#ABABAC]"
        }
      >
        Reviewed <FaAngleDown />
      </Button>
    </div>
  );
}
