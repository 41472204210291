import React from "react";
import { Link } from "react-router-dom";

import { IoLogoLinkedin } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";

const founders = [
  {
    name: "Federico",
    role: "Co-Founder",
    image: "/images/founders/frederico.svg",
    description: "Creative Visionary, Product & Sales",
    linkedin: "https://www.linkedin.com/in/federico-steinhoff/",
    email: "federico@quistra.com",
  },
  {
    name: "Nico",
    role: "Co-Founder",
    image: "/images/founders/nico.svg",
    description: "The technical mastermind of our solution.",
    linkedin: "https://www.linkedin.com/in/nicolas-tarillion-531a551ab/",
    email: "nico@quistra.com",
  },
];

export default function Founders() {
  return (
    <div id="founders" className=" text-center my-24 md:my-32">
      <h2 className=" font-bold text-3xl mb-4">Meet the Founders</h2>
      <p className="text-custom mb-8 max-w-[700px] m-auto">
        Our focus is on practical solutions, driven by our own experiences and
        feedback from users. We’re all about making things easier and more
        efficient for you. Any questions? Feel free to drop us an email, connect
        on LinkedIn or contact us on{" "}
        <a
          href="https://join.slack.com/t/quistra/shared_invite/zt-2jdx5hax3-hrLgIlzIBosQg5edNajnfQ"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          Slack
        </a>{" "}
        or{" "}
        <a
          href="https://discord.gg/UHQZVvHM"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          Discord
        </a>
        .
      </p>

      <div className=" flex justify-center gap-4 flex-wrap lg:flex-nowrap">
        {founders.map((founder, index) => (
          <div
            key={index}
            className="w-80 lg:w-auto flex flex-col lg:flex-row lg:justify-center gap-4 border border-slate-200 rounded-xl overflow-hidden shadow-xl mx-auto"
          >
            <img
              src={founder.image}
              alt={founder.name}
              className=" object-cover w-80 lg:w-auto aspect-square lg:aspect-auto"
            />

            <div className=" text-left p-4 pt-8 lg:w-auto">
              <h3 className=" font-semibold text-2xl">{founder.name}</h3>
              <p className=" text-slate-400 mb-3">{founder.role}</p>
              <p className=" text-custom mb-3">{founder.description}</p>
              <div className=" flex items-center gap-2">
                <Link to={founder.linkedin}>
                  <IoLogoLinkedin />
                </Link>
                <MdOutlineEmail />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
