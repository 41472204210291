import React from "react";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { icons } from "./icons";
import useCompaniesStore from "../../stores/companiesStore";
import { Link } from "react-router-dom";

export default function Company({ name, url }) {
  const { setSelectedCompany } = useCompaniesStore();
  const Icon = icons[name.toLowerCase()];
  return (
    <Link to={"/companies/#search"}>
      <div
        className=" flex items-center gap-2 p-2 text-custom bg-gray-50 my-2 rounded-md hover:bg-gray-200 cursor-pointer"
        onClick={() => setSelectedCompany([url, name])}
      >
        {Icon ? (
          <Icon className=" text-gray-600" />
        ) : (
          <HiOutlineBuildingOffice2 className=" text-gray-400" />
        )}{" "}
        {url}
      </div>
    </Link>
  );
}
