import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

export default function Button({ children, className, loading, ...props }) {
  return (
    <button className={className} {...props}>
      {loading ? <ClipLoader color="gray" size={20} /> : children}
    </button>
  );
}
