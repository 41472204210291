import React from "react";
import { Outlet } from "react-router";
import AuthImage from "../assets/images/auth.svg";
import { Link } from "react-router-dom";

export default function AuthLayout() {
  return (
    <div className=" sm:grid grid-cols-2 h-screen">
      <div className=" p-6 px-10 max-h-screen overflow-y-auto">
        <h1 className=" text-2xl font-bold mb-24">
          <Link to="/">quistra</Link>
        </h1>
        <Outlet />
      </div>

      <img
        src={AuthImage}
        className=" hidden sm:block w-full h-screen object-cover"
        alt="pc"
      />
    </div>
  );
}
