import React from "react";

import Button from "../../components/ui/Button";
import { IoSearchOutline } from "react-icons/io5";
import { IoFilterSharp } from "react-icons/io5";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/ui/popover";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/ui/select";

export default function Filter() {
  return (
    <div className=" flex gap-3 mt-10">
      <div className=" relative flex-1">
        <IoSearchOutline
          size={"20"}
          className=" absolute top-1/2 left-2 -translate-y-1/2"
        />
        <input
          className={"ipt pl-8 text-sm w-full"}
          type={"text"}
          placeholder="Search"
        />
      </div>
      <Popover>
        <PopoverTrigger>
          <Button
            className={
              "btn-secondary border-[#ABABAC] text-sm flex items-center gap-2 justify-center"
            }
          >
            <IoFilterSharp />
            Filter
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-max space-y-4">
          <div>
            <p className=" font-medium mb-1">Profile</p>
            <Select>
              <SelectTrigger className="w-full rounded-xl">
                <SelectValue placeholder="Sort By" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="most-popular">Most Popular</SelectItem>
                <SelectItem value="dark">Most Recent</SelectItem>
                <SelectItem value="system">Oldest</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div>
            <p className=" font-medium mb-1">Updates</p>
            <div className=" grid grid-cols-3 gap-1">
              <Button className="btn-secondary text-sm flex-1">All</Button>
              <Button className="btn-primary text-sm flex-1">Subscribed</Button>
              <Button className="btn-secondary text-sm flex-1">
                Unsubscribed
              </Button>
            </div>
          </div>
          <hr className=" text-slate-400" />
          <div className=" grid grid-cols-2 gap-3">
            <Button className="btn-secondary px-10 text-sm">Cancel</Button>
            <Button className="btn-primary  px-10 text-sm">Apply</Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
