import React, { useContext, useState } from "react";
import Button from "../components/ui/Button";
import { FaArrowRight } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import PasswordInput from "../components/ui/PasswordInput";
import TextInput from "../components/ui/TextInput";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { AppContext } from "../context/AppContext";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { notifyError, notifyWarning } from "../lib/notify";
import { UserAuth } from "../context/AuthContext";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup
      .string()
      .test(
        "password",
        "Password must be at least 7 characters long and contain at least one uppercase letter, one digit, and one special character",
        (value) => {
          return password_validate(value);
        }
      )
      .required(),
    confirmPass: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required(),
  })
  .required();

const password_validate = (pass) => {
  const re = {
    capital: /(?=.*[A-Z])/,
    length: /(?=.{7,40}$)/,
    specialChar: /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/,
    digit: /(?=.*[0-9])/,
  };

  return (
    re.capital.test(pass) &&
    re.length.test(pass) &&
    re.specialChar.test(pass) &&
    re.digit.test(pass)
  );
};

export default function Signup() {
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useContext(AppContext);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { googleSignIn } = UserAuth();
  const usersRef = collection(db, "users");
  const navigate = useNavigate();

  const onSubmit = async ({ email, password }) => {
    console.log(email, password);
    const auth = getAuth();
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user) {
          try {
            setUser(user);
            setDoc(doc(usersRef, user.uid), {
              email,
              uid: user.uid,
            });
            navigate("/");
          } catch (e) {
            console.error("Error adding document: ", e);
            notifyWarning("Error adding document");
          } finally {
            setIsLoading(false);
          }
        }
      })
      .catch((/*error*/) => {
        //const errorCode = error.code;
        //const errorMessage = error.message;
        notifyError("Error creating user");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className=" mb-6">
        <h1 className="text-2xl md:text-4xl font-bold mb-2">Sign Up</h1>
        <p className=" text-sm md:text-base text-custom">
          Please, enter your details.
        </p>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" space-y-4 max-w-[500px] mb-12 md:mb-24"
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextInput
              placeholder={"youremail@gmail.com"}
              className="w-full"
              type={"email"}
              label={"Email"}
              error={errors.email}
              name="email"
              control={field}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <PasswordInput
              placeholder={"Enter Password"}
              className="w-full"
              label={"Password"}
              name="password"
              control={field}
              error={errors.password}
            />
          )}
        />

        <Controller
          name="confirmPass"
          control={control}
          render={({ field }) => (
            <PasswordInput
              placeholder={"Enter Password"}
              className="w-full"
              label={"Repeat password"}
              name="confirmPass"
              control={field}
              error={errors.confirmPass}
            />
          )}
        />

        <Button
          type="submit"
          className=" btn-primary w-full flex items-center justify-center gap-2 py-3"
          loading={isLoading}
          disabled={isLoading}
        >
          Sign Up <FaArrowRight />
        </Button>
        <Button
          type="button"
          className=" btn-secondary w-full flex items-center justify-center gap-2 md:py-3"
          onClick={handleGoogleSignIn}
        >
          <FcGoogle className=" text-2xl" />
          Sign up with Google
        </Button>
      </form>
      <p className=" text-sm md:text-base text-custom">
        Do you have an account?{" "}
        <Link to={"/login"} className=" text-black font-semibold md:font-bold">
          Log In
        </Link>
      </p>
    </div>
  );
}
