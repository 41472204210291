import React from "react";
import { Outlet } from "react-router";
import Navbar from "../components/Navbar";

export default function MainLayout() {
  return (
    <div className=" grid grid-rows-[60px_1fr] min-h-screen">
      <Navbar />
      <Outlet />
    </div>
  );
}
