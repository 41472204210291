import React from "react";
import useCompaniesStore from "../../stores/companiesStore";
import { Link } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import CompaniesList from "./CompaniesList";
import Search from "./Search";

export default function Companies() {
  const { companies, selectedCompany } = useCompaniesStore();
  console.log(companies);

  return (
    <div className=" p-6 md:px-10">
      <Link
        to="/"
        htmlFor="upload-file"
        className={
          " bg-gray-200 text-black font-bold btn-primary mt-2 text-sm p-3 px-6 cursor-pointer flex items-center justify-center gap-2 w-max ml-auto"
        }
      >
        <FiUpload /> Upload File
      </Link>

      <div className=" flex flex-col-reverse md:grid grid-cols-2 gap-5 mt-4 space-y-10 md:space-y-0">
        <CompaniesList />
        {selectedCompany && <Search />}
      </div>
    </div>
  );
}
