import React from "react";
//import Balancer from "react-wrap-balancer";
//import { LuExternalLink } from "react-icons/lu";
import ShowMoreText from "react-show-more-text";

// the analyzed text contains duplications sometime
// so we need to filter out the unique texts
/*function getUniqueTexts(arr) {
  var uniqueTexts = [];
  var textMap = {};

  arr
    ?.filter(({ text }) => text.trim().length)
    .forEach(function (item) {
      if (!textMap[item.text]) {
        textMap[item.text] = true;
        uniqueTexts.push(item);
      }
    });

  return uniqueTexts;
}*/

export default function AnalyzedText({ analyzed, companyName }) {
  //const isUrl =
  //  /^(?:(?:http|https|ftp):\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]+$/;

  fbq('trackCustom', 'CompleteUpload');

  return (
    <ShowMoreText
      /* Default options */
      lines={3}
      more={
        <span className=" text-xs font-extrabold underline">
          {" "}
          <br />
          View All
        </span>
      }
      less={
        <span className=" text-xs font-extrabold underline">
          {" "}
          <br />
          View less
        </span>
      }
      className="content-css flex-1 text-muted-foreground text-sm"
      anchorClass="show-more-less-clickable"
      expanded={false}
      truncatedEndingComponent={"... "}
    >
      {analyzed?.response_text}
    </ShowMoreText>
  );
}

/*function Box({ text, url }) {
  const executeOnClick = (isExpanded) => {
    console.log(isExpanded);
  };
  return (
    <div className=" flex gap-3 justify-center text-center mb-4 p-4 max-w-6xl m-auto bg-gray-50 border border-slate-400">
      <ShowMoreText
        // Default options 
        lines={3}
        more={<span className=" text-sm underline">Show more</span>}
        less={<span className=" text-sm underline">Show less</span>}
        className="content-css flex-1"
        anchorClass="show-more-less-clickable"
        onClick={executeOnClick}
        expanded={false}
        truncatedEndingComponent={"... "}
      >
        {text}
      </ShowMoreText>
      <a href={url} target="_blank" rel="noreferrer">
        <LuExternalLink className=" text-blue-700" />
      </a>
    </div>
  );
}*/
