import React, { useState } from "react";
import Button from "../../components/ui/Button";
import Balancer from "react-wrap-balancer";
import { IoPlayCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Setze den App-Element für A11y

export default function Banner() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className=" text-center space-y-6 my-12 " id="home">
      <h1 className=" font-semibold sm:font-bold text-3xl sm:text-5xl">
        <Balancer>
          Sell Faster to Your Best Leads. Instant Insights, Zero Admin.
        </Balancer>
      </h1>
      <p className=" text-custom text-sm sm:text-base">
        <Balancer>
          Automated insights platform for sales professionals, simplifying
          account prioritization.
        </Balancer>
      </p>
      <div className=" flex items-center gap-2 justify-center">
        <Button
          className={
            "btn-secondary font-medium text-sm flex items-center gap-2 p-3 px-6"
          }
          onClick={openModal}
        >
          <IoPlayCircleOutline size={20} />
          Demo
        </Button>
        <Link
          className={"btn-primary font-medium text-sm p-3 px-6"}
          to="/signup"
        >
          Get started! 
        </Link>
      </div>
      <img src="/images/banner.png" alt="quistra" />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="YouTube Video"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '800px',
            height: '80%'
          },
        }}
      >
        <button onClick={closeModal}>Close</button>
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            src="https://www.youtube.com/embed/Ze7Hn_N4fl4"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube Video"
          ></iframe>
        </div>
      </Modal>
    </div>
  );
}
