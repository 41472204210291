import React from "react";

export default function Footer() {
  return (
    <footer className=" flex items-center justify-between border-t border-slate-200 pt-3 my-6 md:my-12">
      <h1 className=" font-bold text-2xl">quistra</h1>
      <h4>© Copyright 2024</h4>
    </footer>
  );
}
