import React from "react";
import useCompaniesStore from "../../stores/companiesStore";

import Filter from "./Filter";
import Company from "./Company";

export default function CompaniesList() {
  const { companies } = useCompaniesStore();
  console.log(companies);
  return (
    <div className=" shadow-xl rounded-3xl p-4 border border-slate-200">
      <div className=" flex items-center justify-between">
        <h2 className=" font-bold text-xl">Select Company</h2>
        <span className=" text-slate-400 text-sm">
          {companies.length} Updates
        </span>
      </div>
      <Filter />

      <div className=" mt-6">
        {companies.map((company, index) => (
          <Company key={index} name={company[1]} url={company[0]} />
        ))}
      </div>
    </div>
  );
}
