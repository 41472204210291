import React from "react";
import { FiUpload } from "react-icons/fi";
import * as XLSX from "xlsx";
import useCompaniesStore from "../stores/companiesStore";
import { cn } from "../lib/utils";
import { useNavigate } from "react-router-dom";

//const secondary = "#484B4E";

export default function UploadFile({ className }) {
  const { setCompanies } = useCompaniesStore();
  const navigate = useNavigate();

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const textContent = jsonData
          .map((row) =>
            row.filter((cell) => typeof cell === "string" && cell.trim() !== "")
          )
          .filter((row) => row.length > 0)
          .map((row) => row.map((s) => s.trim()));
        textContent.shift();
        setCompanies(textContent);
        navigate("/companies");
      };

      reader.readAsArrayBuffer(file);
    }
  };
  return (
    <label
      htmlFor="upload-file"
      className={cn(
        " btn-primary mt-2 text-sm font-normal p-3 px-6 cursor-pointer flex items-center justify-center gap-2 w-max m-auto",
        className
      )}
    >
      <FiUpload /> Upload File
      <input
        id="upload-file"
        type="file"
        accept=".xlsx, .ods, .csv, .tsv"
        className=" w-0 h-0"
        onChange={handleFileInputChange}
      />
    </label>
  );
}
