import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { useEffect, useState } from "react";
import { AppContext } from "./context/AppContext";
import { onAuthStateChanged } from "firebase/auth";
//import CircularProgress from "@mui/material/CircularProgress";
import { auth } from "./firebase";
//import Dashboard from "./Pages/Dashboard";
import AuthLayout from "./layouts/AuthLayout";
import Signup1 from "./Pages/SignUp1";
import Login1 from "./Pages/Login1";

import "react-toastify/dist/ReactToastify.css";
import { NotifyContainer } from "./lib/notify";
import MainLayout from "./layouts/MainLayout";
import OpenDoc from "./Pages/openDoc/index";
//import OldOpenDoc from "./Pages/OpenDoc";
import LandingPage from "./Pages/landing";
import Companies from "./Pages/companies";
import Jobs from "./Pages/jobs";
import GridLoader from "react-spinners/GridLoader";

function App() {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
      setLoading(false);
    });

    return () => {
      listen();
    };
  }, []);
  const PrivateRoute = () =>
    loading ? (
      <div className="absolute z-10 top-0 right-0 bottom-0 left-0 flex justify-center items-center">
        <GridLoader color="#484B4E" />
      </div>
    ) : (
      <Routes>
        {user ? (
          <>
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<OpenDoc />} />
              <Route path="/companies" element={<Companies />} />
              <Route path="/dashboard" element={<Jobs />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </>
        ) : (
          <>
            <Route path="/" element={<LandingPage />} />
            <Route path="/" element={<AuthLayout />}>
              <Route path="/login" element={<Login1 />} />
              <Route path="/signup" element={<Signup1 />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Route>
          </>
        )}
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/signup" element={<SignUp />} /> */}
      </Routes>
    );

  return (
    <AuthContextProvider>
      <AppContext.Provider value={{ setUser, user, setLoading }}>
        <BrowserRouter>
          <PrivateRoute />
          <NotifyContainer />
        </BrowserRouter>
      </AppContext.Provider>
    </AuthContextProvider>
  );
}

export default App;
