import React from "react";
//import { useEffect, useState, useContext } from "react";
//import { useEffect, useState } from "react";
import { useEffect } from "react";
//import { AppContext } from "../../context/AppContext";
//import { signOut } from "firebase/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { Button } from "@mui/material";
//import axios from "axios";
//import Job from "./Job";
import AnalyzedText from "../../components/AnalyzedText";
//import AlertDialogSlide from "../../components/Modal";
//import { auth } from "../../firebase";
//import UploadFile from "../../components/UploadFile";
import { FiUpload } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import Filter from "./Filter";
import JobsList from "./Jobs";

export default function Jobs() {
  // =========================================
  // ================ STATES =================
  // =========================================
  //const [resData, setResData] = useState([]);
  //const [selectedData, setSelectedData] = useState({});
  //const [isLoading, setIsLoading] = useState(false);
  //const [open, setOpen] = React.useState(false);
  //const { setUser, user } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  // =========================================
  // ================ EFFECTS ==============
  // =========================================
  useEffect(() => {
    //setResData(state.data);
    console.log(state.data);
  }, [state]);

  // =========================================
  // ================ HANDLERS ==============
  // =========================================

  // this function is used to fetch the previous or next page data
  // still not working due to invalid api key probably
  /*const fetchData = (url) => {
    setIsLoading(true);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${process.env.API_KEY}`,
        },
      })
      .then((data) => {
        setResData(data.company_response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };*/

  /*const logout = () => {
    navigate("/");
    signOut(auth).then(setUser());
  };*/

  // =========================================
  // ================ RENDER ==============
  // =========================================

  return (
    <div className=" max-w-6xl mx-auto p-6">
      <div className="flex items-start justify-between gap-64 w-full mt-10">
        <div className=" flex items-start gap-3 flex-1">
          <IoIosArrowBack
            className=" mt-2 cursor-pointer"
            onClick={() => navigate("/companies")}
          />
          <div className=" space-y-3 ">
            <div className=" flex items-center gap-2">
              <h1 className=" text-2xl font-bold">{state.company.url}</h1>
              <p className=" font-semibold text-muted-foreground mt-1">
                {state.company.name}
              </p>
            </div>

            <AnalyzedText analyzed={state.analyzed} />
          </div>
        </div>
        <Link
          to="/"
          htmlFor="upload-file"
          className={
            " bg-gray-200 text-black font-bold btn-primary mt-2 text-sm p-3 px-6 cursor-pointer flex items-center justify-center gap-2 w-max"
          }
        >
          <FiUpload /> Upload File
        </Link>
      </div>
      <Filter />
      <JobsList jobs={state.data.hits} />
    </div>
  );
}
