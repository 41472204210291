import React from "react";

const features = [
  {
    icon: "/images/about/insight.svg",
    title: "Instant Insights",
    description:
      "Our software scans both current and past job listings to give you detailed information on hiring trends. Just enter a keyword, and Quistra will show you if and how companies have been hiring for that skill. Plus, you'll get insights about the company, including how they make money and what products they offer. ",
  },
  {
    icon: "/images/about/alert.svg",
    title: "Real-time Alerts",
    description:
      "Get notified the moment a company starts hiring for the skills/technologies you care about. You'll never miss an opportunity again.",
  },
];

export default function About() {
  return (
    <div
      className=" flex flex-col lg:flex-row gap-10 items-center lg:items-start justify-center lg:justify-start sm:my-12 text-center"
      id="about"
    >
      <div className=" flex-1 space-y-3">
        <h2 className=" text-2xl sm:text-4xl font-bold">About Our Software</h2>
        <p className=" text-custom text-lg leading-tight">
        We make it easy for you to find key information from job listings, whether they're current or from the past.
        </p>
      </div>
      <div className=" flex-1 lg:space-y-6 grid grid-cols-1 sm:grid-cols-2 gap-4 lg:block">
        {features.map(({ icon, title, description }, index) => (
          <div
            key={index}
            className=" flex flex-col lg:flex-row items-center justify-start lg:items-start gap-4 border border-slate-200 lg:border-none rounded-md p-3"
          >
            <img src={icon} alt="" />
            <div className=" mt-2">
              <h3 className=" font-semibold text-lg">{title}</h3>
              <p className=" font-light text-custom">{description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
