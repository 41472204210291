import React, { useState, useContext } from "react";
import useCompaniesStore from "../../stores/companiesStore";
import { addDays, format } from "date-fns";
import { DatePickerWithRange } from "../../shadcn/ui/period";
import { FaPlus } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { Checkbox } from "../../shadcn/ui/checkbox";
import { cn } from "../../lib/utils";
import Button from "../../components/ui/Button";
import { MdOutlineEmail } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";
import { RiShareBoxLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

export default function Search() {
  const { selectedCompany } = useCompaniesStore();
  const [keyword, setKeyword] = useState("");
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const { user, setLoading } = useContext(AppContext);
  const [date, setDate] = React.useState({
    from: new Date(2024, 0, 20),
    to: addDays(new Date(2024, 4, 20), 20),
  });
  const [url, name] = selectedCompany;
  const navigate = useNavigate();

  const addKeyword = () => {
    if (keyword.trim().length === 0) return;
    setKeywords((prev) => [...prev, keyword]);
    setKeyword("");
  };

  const deleteKeyword = (keyword) => {
    setKeywords((prev) => prev.filter((k) => k !== keyword));
  };

  const handleSubmit = (e) => {
    const formData = {
      start_date: format(date.from, "yyyy-MM-dd"),
      end_date: format(date.to, "yyyy-MM-dd"),
      keywords,
      trigger_events: checked,
      email_trigger_events: email,
      email,
      company_name: selectedCompany[1],
    };
    console.log(formData);
    // const formData = {
    //   company_name: "Google",
    //   start_date: "2024-01-01",
    //   end_date: "2024-04-30",
    //   keywords: ["Python"],
    //   trigger_events: true,
    //   email_trigger_events: "federico@quistra.com",
    // };
    setLoading(true);
    fetch("https://contractstream-ccc.ey.r.appspot.com/process", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        navigate("/dashboard", {
          state: {
            data: data.company_response,
            company: {
              url: selectedCompany[0],
              name: selectedCompany[1],
            },
            analyzed: data.analyzed_text,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
    console.log(formData);
  };

  const reset = () => {
    setDate({
      from: new Date(2022, 0, 20),
      to: addDays(new Date(2022, 0, 20), 20),
    });
    setKeywords([]);
    setKeyword("");
    setEmail("");
    setChecked(false);
  };

  return (
    <div
      id="search"
      className=" shadow-xl rounded-3xl p-4 border border-slate-200"
    >
      <div className=" flex items-center gap-2">
        <h2 className=" text-xl font-bold">{url}</h2>
        <p className=" text-sm text-gray-500 ">{name}</p>
      </div>
      <div className="mt-6 space-y-6">
        <div>
          <p className="mb-1 text-custom text-sm">Select the date range</p>
          <DatePickerWithRange date={date} setDate={setDate} />
        </div>
        <div>
          <p className="mb-1 text-custom text-sm">Keywords</p>
          <div className="flex gap-1">
            <input
              className="ipt flex-1 text-sm"
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Enter keywords"
            />
            <p
              className={cn(
                "rounded-full p-2 border border-[#ABABAC] cursor-pointer",
                keyword.length ? "visible" : "invisible"
              )}
              onClick={addKeyword}
            >
              <FaPlus size={20} />
            </p>
          </div>
          <div className="flex gap-2 mt-2 flex-wrap">
            {keywords.map((keyword, index) => (
              <Button
                className="btn-secondary flex items-center gap-2 py-1 text-sm px-2"
                key={index}
                onClick={() => deleteKeyword(keyword)}
              >
                {keyword}
                <IoMdClose size={15} />
              </Button>
            ))}
          </div>
        </div>
        <div className="items-top flex space-x-2">
          <Checkbox
            checked={checked}
            onCheckedChange={setChecked}
            id="terms1"
          />
          <div className="grid gap-1.5 leading-none">
            <label
              htmlFor="terms1"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Email Trigger Events
            </label>
            <p className="text-sm text-muted-foreground">
              You will receive the jobs posted instantly via email.
            </p>
          </div>
        </div>
        {checked && (
          <div className=" space-y-1">
            <label className={cn("text-sm")}>Email</label>
            <div className=" relative ">
              <p className=" absolute top-1/2 left-2 -translate-y-1/2 ">
                <MdOutlineEmail size={16} className=" text-custom" />
              </p>
              <input
                className={cn("ipt pl-8 text-sm w-full")}
                type={"email"}
                placeholder="youremail@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <p className="text-sm text-muted-foreground">
              Updates will be sent to this email only
            </p>
          </div>
        )}
        <div className="flex gap-2 justify-end">
          <Button
            className="btn-secondary flex items-center gap-2 text-sm"
            onClick={reset}
          >
            <GrPowerReset /> Reset Update
          </Button>
          <Button
            className="btn-primary flex items-center gap-2 text-sm"
            onClick={handleSubmit}
          >
            <RiShareBoxLine /> Share Updates
          </Button>
        </div>
      </div>
    </div>
  );
}
