import React, { useState } from "react";
import UploadFile from "../../components/UploadFile";
import Modal from "react-modal";

export default function OpenDoc() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="relative px-3">
      <div className="h-full grid place-content-center">
        <div className="text-center">
          <img src="/images/doc.svg" alt="upload" className="m-auto mb-2" />
          <h2 className="font-semibold text-xl mb-2">Start to create</h2>
          <p className="max-w-lg text-custom mb-6">
            Your project will live here. Start search and create by clicking on
            “Upload File”. The tool currently supports .xlsx, .ods, .csv and
            .tsv files. Here is an{" "}
            <span
              onClick={openModal}
              className="text-blue-500 underline cursor-pointer"
            >
              example
            </span>
            .
          </p>
          <UploadFile />
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example spreadsheet"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "2rem",
          },
        }}
      >
        <button
          onClick={closeModal}
          style={{ display: "block", marginBottom: "1rem", cursor: "pointer" }}
        >
          Close
        </button>
        <img
          src="/images/upload/Upload_example.png"
          alt="Example spreadsheet"
        />
      </Modal>
    </div>
  );
}
