import React from "react";
import { cn } from "../../lib/utils";
import ErrorMessage from "./ErrorMessage";

export default function TextInput({
  label,
  className,
  type,
  control,
  error,
  ...props
}) {
  return (
    <div className=" space-y-1">
      <label className={cn("text-sm", error ? "text-red-500" : "")}>
        {label}
      </label>
      <input
        className={cn("ipt", className, error ? "ipt-error" : "")}
        type={type}
        {...control}
        {...props}
      />
      {error && <ErrorMessage message={error.message} />}
    </div>
  );
}
