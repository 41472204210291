import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/ui/Button";
import { useMediaQuery } from "usehooks-ts";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";

const links = [
  {
    label: "Home",
    href: "#home",
  },
  {
    label: "About",
    href: "#about",
  },
  {
    label: "Problem",
    href: "#problem",
  },
  {
    label: "Solution",
    href: "#solution",
  },
  {
    label: "Founders",
    href: "#founders",
  },
];

export default function Navbar() {
  const matches = useMediaQuery("(min-width: 1024px)");
  const [isOpen, setIsOpen] = useState();

  const handleToggle = () => setIsOpen(!isOpen);
  return (
    <nav className=" flex items-center justify-between lg:justify-normal gap-12 p-3 px-12 border-b border-slate-200">
      <h1 className=" text-2xl font-bold">quistra</h1>
      {matches ? (
        <>
          <ul className="flex items-center gap-8 flex-1 ">
            {links.map(({ label, href }, index) => (
              <li key={index}>
                <a href={href} className=" text-gray-700 font-semibold">
                  {label}
                </a>
              </li>
            ))}
          </ul>
          <div className=" flex items-center gap-2">
            <Link className={"btn-secondary font-medium text-sm"} to="/login">
              Log in
            </Link>

            <Link className={"btn-primary font-medium text-sm"} to="/signup">
              Sign up
            </Link>
          </div>
        </>
      ) : isOpen ? (
        <IoMdClose size={24} onClick={handleToggle} />
      ) : (
        <GiHamburgerMenu size={24} onClick={handleToggle} />
      )}

      {isOpen && (
        <div className="absolute z-10 top-14 left-0 right-0 bg-white border-y border-slate-200 pt-3">
          <ul className="flex flex-col justify-center items-center gap-4 flex-1 ">
            {links.map(({ label, href }, index) => (
              <li key={index}>
                <a href={href} className=" text-gray-700 font-semibold">
                  {label}
                </a>
              </li>
            ))}
          </ul>

          <div className=" flex items-center justify-center gap-2 my-3">
            <Link
              className={"btn-secondary font-medium text-sm px-6"}
              to="/login"
            >
              Log in
            </Link>

            <Link
              className={"btn-primary font-medium text-sm px-6"}
              to="/signup"
            >
              Sign up
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
}
