import React from "react";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { cn } from "../../lib/utils";
import ErrorMessage from "./ErrorMessage";

export default function InputPassword({
  label,
  className,
  control,
  error,
  ...props
}) {
  const [type, setType] = React.useState("password");
  return (
    <div className=" space-y-1">
      <label className={cn("text-sm", error ? "text-red-500" : "")}>
        {label}
      </label>
      <div className=" relative">
        <input
          className={cn("ipt pr-6", className, error ? "ipt-error" : "")}
          type={type}
          {...control}
          {...props}
        />
        {type === "password" ? (
          <FaRegEyeSlash
            onClick={() => setType("text")}
            className=" absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
          />
        ) : (
          <IoEyeOutline
            onClick={() => setType("password")}
            className=" absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
          />
        )}
      </div>
      {error && <ErrorMessage message={error.message} />}
    </div>
  );
}
