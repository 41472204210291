import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/ui/dialog";
import Button from "../../components/ui/Button";
//import useCompaniesStore from "../../stores/companiesStore";

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export default function Job({ job, index }) {
  const {
    datePosted,
    description,
    employmentType,
    //hiringOrganizationName,
    title,
    //status,
    url,
  } = job;

  //export default function Job({ index, job_title, location, job_url, onClick }) {
  return (
    <div className=" border border-border bg-white rounded-lg overflow-hidden shadow-md transition duration-200 hover:shadow-sm cursor-pointer">
      {/* <img src="/images/job.png" alt="job" /> */}
      <div className=" p-3 px-5">
        <div>
          <div className=" flex items-center justify-between text-sm text-muted-foreground font-medium">
            <span>Job {index + 1}</span>
            <span>{formatDate(datePosted)}</span>
          </div>

          <h2 className=" mt-3 text-normal">{title}</h2>
          <span className=" text-sm text-slate-400">
            {employmentType && employmentType.toLowerCase()}
          </span>
          <p className=" text-custom line-clamp-3 text-xs mt-2">
            {description}
          </p>
        </div>
        <div className=" space-y-3 mt-4">
          <hr className=" text-muted-foreground" />
          <Dialog>
            <DialogTrigger>
              <Button
                target="_blank"
                className={
                  " btn-secondary text-sm flex items-center justify-center gap-2 ml-auto px-6"
                }
              >
                View Full <FaArrowRightLong />
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>{title}</DialogTitle>
                <DialogDescription>
                  <hr className=" text-slate-200 my-4" />
                  <p className=" max-h-80 overflow-y-scroll">{description}</p>
                  <Link
                    to={url}
                    target="_blank"
                    className={
                      " btn-secondary text-sm flex items-center justify-center gap-2 ml-auto px-6 mt-3 border border-slate-400"
                    }
                  >
                    View Job <FaArrowRightLong />
                  </Link>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
