import { create } from "zustand";

const useCompaniesStore = create((set) => ({
  companies: [],
  setCompanies: (companies) => set({ companies }),
  selectedCompany: null,
  setSelectedCompany: (selectedCompany) => set({ selectedCompany }),
  clear: () => set({ companies: [] }),
}));

export default useCompaniesStore;
