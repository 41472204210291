import React from "react";
import Balancer from "react-wrap-balancer";

export default function Solution() {
  return (
    <div className=" text-center mt-32" id="solution">
      <h2 className=" font-bold text-3xl mb-4">Our Solution</h2>
      <p className="text-custom mb-8 max-w-xl mx-auto">
        <Balancer>
          We started <span className="font-bold">Quistra</span> to solve this issue. Our software is like a personal assistant, tracking historical job postings and current hiring trends. 
          When a company starts hiring for a skill you’re interested in, you’ll get a push notification. 
          While we come from a sales background, we believe <span className="font-bold">Quistra</span> is useful for other professions too. 
          Our Beta is live—give it a try and let us know what you think. 
          Your feedback will help us improve. Join our{' '} 
          <a href="https://join.slack.com/t/quistra/shared_invite/zt-2jdx5hax3-hrLgIlzIBosQg5edNajnfQ" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Slack</a> and{' '} 
          <a href="https://discord.gg/UHQZVvHM" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Discord</a> to stay updated.
        </Balancer>
      </p>
      <img src="/images/solution.svg" alt="details" />
    </div>
  );
}
