import { FaFacebook } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { FaAmazon } from "react-icons/fa";
import { FaMicrosoft } from "react-icons/fa";
import { SiNike } from "react-icons/si";
import { TbBrandDisney } from "react-icons/tb";

export const icons = {
  meta: FaFacebook,
  google: FaGoogle,
  apple: FaApple,
  amazon: FaAmazon,
  microsoft: FaMicrosoft,
  nike: SiNike,
  disney: TbBrandDisney,
};
