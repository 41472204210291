import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInput from "../../components/ui/TextInput";
import Button from "../../components/ui/Button";
import { Link } from "react-router-dom";

const schema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
  })
  .required();

export default function Contact() {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email, password }) => {
    console.log(email, password);
  };
  return (
    <div>
      <h2 className=" text-3xl lg:text-4xl font-bold text-center mb-4">
        Get ahead in sales management with <br /> Quistra's upcoming solution.
      </h2>
      <p className=" text-custom text-center mb-8 md:mb-16">
        Sign up now to stay updated and be the first to experience it.
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" md:grid grid-cols-2 gap-2 space-y-3 md:space-y-0  max-w-[550px] m-auto"
      >
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextInput
              placeholder={"Enter First Name"}
              className="w-full"
              type={"text"}
              label={"First Name"}
              error={errors.firstName}
              name="firstName"
              control={field}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextInput
              placeholder={"Enter Last Name"}
              className="w-full"
              type={"text"}
              label={"Last Name"}
              error={errors.lastName}
              name="lastName"
              control={field}
            />
          )}
        />
        <div className=" col-span-2 my-2">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                placeholder={"youremail@gmail.com"}
                className="w-full col-span-2"
                type={"email"}
                label={"Email"}
                error={errors.email}
                name="email"
                control={field}
              />
            )}
          />
        </div>
        <Button
          type="submit"
          className=" col-span-2 btn-primary w-full flex items-center justify-center gap-2 py-3"
        >
          <Link to={"/signup"}>Sign Up Now!</Link>
        </Button>
      </form>
    </div>
  );
}
