import React from "react";
import Job from "./Job";

export default function Jobs({ jobs }) {
  return (
    <div className=" grid grid-cols-3 gap-4 mt-6">
      {jobs.map((job, index) => (
        <Job key={index} job={job} index={index} />
      ))}
    </div>
  );
}
