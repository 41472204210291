import React from "react";

export default function Problem() {
  return (
    <div
      className=" flex flex-col-reverse items-center justify-center md:grid grid-cols-2 gap-10 my-12 mt-24 md:mt-32"
      id="problem"
    >
      <div className=" relative bg-[#F2F4FF] rounded-xl overflow-hidden">
        <img
          src="/images/problem.svg"
          className=" w-full h-full object-cover"
          alt="companies"
        />
      </div>

      <div className=" flex flex-col justify-center gap-2 text-center md:text-left">
        <h2 className=" font-bold text-3xl mb-4">The Problem</h2>
        <p className=" text-custom">
        From our sales background in SaaS, we've seen how job listings reveal what companies care about. 
        Businesses put their money where their mouth is, hiring for mission-critical roles. 
        But searching each company for specific keywords manually is a pain and only shows current postings. 
        What if Company X hired for skill Y a few months ago, but the posting is gone? 
        Or Company Z starts hiring for skill X <u><strong>after</strong></u> your research? How do you not miss these chances?
        </p>
      </div>
    </div>
  );
}
