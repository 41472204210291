import React from "react";
import { useContext } from "react";
import { auth } from "../firebase";
import { AppContext } from "../context/AppContext";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { PiSignOutBold } from "react-icons/pi";

export default function Navbar() {
  const navigate = useNavigate();
  const { setUser } = useContext(AppContext);

  const logout = () => {
    navigate("/");
    signOut(auth).then(setUser());
  };
  return (
    <nav className=" flex items-center justify-between border-b border-slate-200 p-2 px-6">
      <h1 className=" font-bold text-2xl">quistra</h1>
      <button
        onClick={() => logout()}
        className="text-black font-semibold text-sm px-3 py-1 cursor-pointer flex items-center gap-2"
      >
        Sign out
        <PiSignOutBold size={24} />
      </button>
    </nav>
  );
}
